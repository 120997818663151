import { DownCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import axios, { AxiosError } from 'axios';
import { CustomTitle, showNotification } from 'components/elements';
import { useSetPageTitle } from 'hook';
import fileDownload from 'js-file-download';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import useStores from 'stores';
import { EnumRoleTypes, ErrorResponse } from 'types';
import { handleAxiosError } from 'utils';

export const Terminal: React.FC = observer(() => {
  const { containerStore, userStore, authStore } = useStores();
  const [logData, setLogData] = useState<string[]>([]);
  const divLogRef = useRef<HTMLDivElement>(null);
  const containerId = toJS(containerStore?.currentContainer?.containerId);
  const username = toJS(userStore?.currentUser?.username);
  const { t } = useTranslation();
  useSetPageTitle("terminal");

  useEffect(() => {
    const getContainer = () => {
        if (userStore.currentUser?.role !== EnumRoleTypes.ADMIN) {
          containerStore.getContainer().then((res) => {
            if (!res?.container) {
              showNotification(('start-container-before-watch'), 'warning');
            }
          }).catch((error) => {
            handleAxiosError(error as AxiosError<ErrorResponse>)
          });
        }
    };

    if (!containerId || !username) {
      getContainer();
    }

    const socket = io(`${process.env.REACT_APP_SOCKET_URL || 'http://localhost:3000'}`, { transports: ['websocket'] });

    socket.on('connect', () => {
      socket.emit('messageToServer', {
        containerId,
        username
      });
    });

    socket.on('logData', (line) => {
      if (Array.isArray(line)) {
        setLogData(line);
      } else {
        setLogData((prev) => [...prev, line]);
      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    return () => {
      socket.disconnect();
    };
  }, [containerId, username]);

  useEffect(() => {
    if (divLogRef.current) {
      divLogRef.current.scrollTop = divLogRef.current.scrollHeight;
    }
  }, [logData]);

  const handleDownloadResult = () => {
    const date = new Date();
    const formattedDate = date.toISOString().slice(0, 10);
    const fileName = `${formattedDate}_sample_output.sam`;

    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}directory-system/download-result`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    })
      .then((response) => {
        fileDownload(response.data, fileName);
        showNotification(('success-to-download-file'), 'success');
      })
      .catch((error) => showNotification(('failed-to-download-file'), 'error'));
  };

  const handleDownloadStatic = () => {
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}directory-system/download-static`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    })
      .then((response) => {
        const { fileName, fileData } = response.data;
        const uint8Array = new Uint8Array(fileData.data);
        const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        fileDownload(blob, fileName);
        showNotification(('success-to-download-file'), 'success');
      })
      .catch((error) => {
        showNotification(('failed-to-download-file'), 'error');
      });
  };
  return (
    <div className='flex flex-col min-h-full pt-20 gap-y-6 items-center justify-start '>
      <div className='w-full sm:flex-row flex-col gap-y-2 flex justify-between sm:items-center items-start'>
        <CustomTitle text={t('terminal')} />
        <div className='flex gap-x-3'>
          <Button
            onClick={handleDownloadResult} className="bg-darkBlue-200 text-">
            {t('download-result-file')}
              <DownCircleOutlined className='max-w-7 max-h-7' />
          </Button>
          <Button
            onClick={handleDownloadStatic} className="bg-darkBlue-200 text-">
            {t('download-stat-file')}
              <DownCircleOutlined className="max-w-7 max-h-7" />
          </Button>
        </div>
      </div>
      <div className='w-full bg-gray-500 p-5 h-[calc(100vh-18rem)]'>
        <div className='space-y-5 overflow-y-auto h-full customScroll flex flex-col' ref={divLogRef}>
            {logData.map((line, index) => (
              <div key={index}>{line}</div>
            ))}
        </div>
      </div>
    </div>
  );
});

