import { makeAutoObservable } from "mobx";
import { AuthRequest } from "../request";
import { NavigateFunction } from "react-router-dom";
import { webRoutes } from "../routes/web";
import userStore from "./userStore";
import { HttpStatusCode, LoginResponse } from "types";
class AuthStore {
    token?: string = localStorage.getItem('jwt') || sessionStorage.getItem('jwt') || undefined;

    constructor() {
        makeAutoObservable(this);
        this.loadToken();
    }

    loadToken() {
        const token = localStorage.getItem("jwt");
        if (token) {
            this.token = token;
        }
    }
    async login(email: string, password: string): Promise<LoginResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await AuthRequest.login(email, password);
                if (res.data.statusCode === HttpStatusCode.OK && res.data.user) {
                    localStorage.setItem("jwt", res.data.user.access_token);
                    this.token = res.data.user.access_token;
                    resolve(res.data);
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            }
        })
    }

    logout(navigate: NavigateFunction) {
        localStorage.removeItem("jwt");
        userStore.currentUser = null;
        this.token = undefined;
        navigate(`${webRoutes.auth.base}/${webRoutes.auth.login}` || webRoutes.default);
    }
}

const authStore = new AuthStore();
export default authStore;
