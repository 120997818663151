import { makeAutoObservable, runInAction } from "mobx";
import { AuthRequest, UserRequest } from "../request";
import { CancelToken } from "axios";
import {
  CommonUserType,
  DataResponse,
  EnumLanguage,
  EnumRoleTypes,
  HttpStatusCode,
  Language,
  LicenseResponse,
  UserResponse,
  UserType,
} from "types";
import { Languages } from "constant";
import commonStore from "./commonStore";
import { showNotification } from "components/elements";
interface UserAction {
  type: string;
  data?: UserType;
}
class UserStore {
  users: UserType[] = [];
  currentUser: UserType | null = null;
  currentRole: string = this.currentUser?.role || EnumRoleTypes.USER;
  userAction: UserAction = { type: "", data: undefined };
  page: number = 1;
  limit: number = 10;
  total: number = 2;
  from: number = 1;
  to: number = 10;
  totalPages: number = 0;
  search: string = "";
  showModalUser: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  reset = () => {
    this.page = 1;
    this.limit = 10;
    this.search = "";
  };

  setLimit = (limit: number) => (this.limit = limit);

  setSearch = (Search: string) => (this.search = Search);

  setPage = (Page: number) => (this.page = Page);

  setCurrentUser = (data: UserType) => (this.currentUser = data);

  setPagination = (data: any) => {
    const { limit, from, to, page, total } = data;
    this.limit = limit;
    this.page = page;
    this.total = total;
    this.from = from;
    this.to = to;
  };

  setUsers = (data: UserType[]) => {
    this.users = data;
  };

  getCurrentUser = (): Promise<UserResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await AuthRequest.getCurrentUser();
        if (res.data.statusCode === HttpStatusCode.OK && res.data.user) {
          this.setCurrentUser(res.data.user);
          this.currentRole = res.data.user.role;
          const lng = res.data.user.default_language || EnumLanguage.JP;
          const lang = Languages.find((c) => c.key === lng);

          if (lang) {
            commonStore.setSelectedLanguage(lang);
            commonStore.currentLanguage = lng;
          }
        }
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllUsers = async (
    page?: number,
    limit?: number,
    search?: string,
    cancelToken?: CancelToken
  ): Promise<DataResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await UserRequest.getAllUsers(
          page,
          limit,
          search,
          cancelToken
        );
        if (res.data.statusCode === HttpStatusCode.OK && res.data.users) {
          const filterUser = res?.data.users.filter(
            (item: UserType) => item.deleted === false
          );
          runInAction(() => {
            this.setUsers(filterUser);
            this.page = Number(res.data.page);
            this.limit = Number(res.data.limit);
            this.total = res.data.total;
            this.from = res.data.from;
            this.to = res.data.to;
          });
        }
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  updateUser = async (
    data: CommonUserType,
    id: string | undefined
  ): Promise<UserResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await UserRequest.updateUser(data, id);
        if (res.data.statusCode === HttpStatusCode.OK && res.data.user) {
          const updatedUser = res.data.user;
          const updatedUsers = this.users.map((user) =>
            user._id === updatedUser._id ? updatedUser : user
          );
          this.setUsers(updatedUsers);
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  updateProfile = async (
    data: CommonUserType,
    callback?: () => void
  ): Promise<UserResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const id = this.currentUser?._id;
        if (id) {
          const res = await UserRequest.updateUser(data, id);
          if (res.data.statusCode === HttpStatusCode.OK && res.data.user) {
            const updatedUser = res.data.user;
            this.setCurrentUser(updatedUser);
            callback && callback();
            resolve(res.data);
          } else {
            reject(res.data);
          }
        } else {
          showNotification("no-user", "error");
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  setUserAction = (type: string, data?: UserType) => {
    this.userAction.type = type;
    this.userAction.data = data;
    this.toggleModalUser();
  };

  deleteUser = (id: string | undefined): Promise<UserResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await UserRequest.deleteUser(id);
        if (res.data.statusCode === HttpStatusCode.OK && res.data?.user) {
          const remainingUsers = this.users.filter((user) => user._id !== id);
          this.setUsers(remainingUsers);
          this.to = this.to - 1;
          this.total = this.total - 1;
          showNotification("delete-successfully", "success");
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  createUser = async (data: CommonUserType): Promise<UserResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await UserRequest.createUser(data);
        if (res.data.statusCode === HttpStatusCode.CREATED && res.data.user) {
          this.users.push(res.data.user);

          this.total += 1;

          const startIndex = (this.page - 1) * this.limit;
          const endIndex = startIndex + this.limit;

          if (this.users.length > endIndex) {
            this.users = this.users.slice(startIndex, endIndex);
          }

          this.from = startIndex + 1;
          this.to = Math.min(this.from + this.users.length - 1, this.total);
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  checkLiscense = (content: string): Promise<LicenseResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await UserRequest.checkLicense(content);
        if (
          this.currentUser &&
          res.data.statusCode === HttpStatusCode.OK &&
          res.data.license
        ) {
          this.currentUser.license = true;
          resolve(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  changeLanguage = (item: Language) => {
    if (this.currentUser) {
      const { email, username, is_use_shared_workspace, role } =
        this.currentUser;
      const data: CommonUserType = {
        email,
        username,
        is_use_shared_workspace,
        role,
        default_language: item.key || EnumLanguage.JP,
      };
      const callback = () => {
        commonStore.setSelectedLanguage(item);
      };
      this.updateProfile(data, callback);
    }
  };

  toggleModalUser = () => {
    this.showModalUser = !this.showModalUser;
  };
}

const userStore = new UserStore();
export default userStore;
