import { Checkbox, Form, Input, InputNumber, Switch } from 'antd';
import { AxiosError } from 'axios';
import { FormLabel, ModalContainer, showNotification } from 'components/elements';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { webRoutes } from 'routes/web';
import useStores from 'stores';
import { ErrorResponse, FormDataVCall } from 'types';
import { filterExistingValues, handleAxiosError, handleRemoveSpaceInput } from 'utils';
interface CommandProps {
    nameCmd: string;
}

export const ModalCommandVCall: React.FC<CommandProps> = observer(({ nameCmd }) => {
    const { t } = useTranslation();
    const { commonStore, parameterStore } = useStores();
    const { toggleModalCommand, showModalCommand } = commonStore
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [form] = Form.useForm<FormDataVCall>()
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const navigate = useNavigate()

    const onSubmit = () => {
        form.validateFields().then((values) => {
            setIsSubmit(true)
            parameterStore.runCommand('vcall', filterExistingValues(values)).then(() => {
                showNotification('notification.command.success', 'success');
                navigate(`${webRoutes.dashboard.user.terminal}`)
                toggleModalCommand()
            }).catch((error) => {
                handleAxiosError(error as AxiosError<ErrorResponse>);
            }).finally(() => {
                setIsSubmit(false)
            });
        }).catch(() => { });
    };

    const handleClose = () => toggleModalCommand()
    const handleShowAdvanceParameter = () => setIsChecked(!isChecked)

    return (
        <ModalContainer
            open={showModalCommand}
            title={`${t('command')} ${nameCmd}`}
            onCancel={handleClose}
            onOk={onSubmit}
            isSubmit={isSubmit}
            okText={'commons.run'}
        >
            <Form onFinish={onSubmit} form={form} layout='vertical' className='px-5'>
                <div className='space-y-2'>
                    <FormLabel text={t('basic-parameter')} />
                    <Form.Item
                        label={t('params.vcall.final-bam-name')}
                        name="B"
                    >
                        <Input
                            type="text"
                            placeholder="test.bam"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'B', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.input-samfile-directory-name')}
                        name="D"
                    >
                        <Input
                            type="text"
                            placeholder="result"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'D', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.index-files-base-name')}
                        name="i"
                    >
                        <Input
                            type="text"
                            placeholder="indexdir/hashtmp"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'i', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.input-samfile-base-name')}
                        name="s"
                    >
                        <Input
                            type="text"
                            placeholder="test"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 's', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.input-dbsnp-files-base-name')}
                        name="S"
                    >
                        <Input
                            type="text"
                            placeholder="dbsnp"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'S', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.stat-file-name')}
                        name="T"
                    >
                        <Input
                            type="text"
                            placeholder="vcall-stat.txt"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'T', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('params.vcall.final-vcf-name')}
                        name="V"
                    >
                        <Input
                            type="text"
                            placeholder="test.vcf.gz"
                            onChange={(e) => {
                                handleRemoveSpaceInput(e, 'V', form);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormLabel text={t('advance-parameter')} />}
                    >
                        <Switch onChange={() => handleShowAdvanceParameter()} />
                    </Form.Item>

                    {isChecked && (
                        <>
                            <Form.Item
                                name='a'
                                valuePropName='checked'
                            >
                                <Checkbox>
                                    {t('params.vcall.additional-vall-options')}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                label={t('params.vcall.debug')}
                                name="d"
                            >
                                <InputNumber
                                    placeholder="0"
                                    precision={0}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('params.vcall.suffix-length')}
                                name="l"
                            >
                                <InputNumber
                                    precision={0}
                                    placeholder="4"
                                />
                            </Form.Item>

                            <Form.Item
                                name='m'
                                valuePropName='checked'
                            >
                                <Checkbox>
                                    {t('params.vcall.make-bam')}
                                </Checkbox>
                            </Form.Item>

                            <Form.Item
                                label={t('params.vcall.number-of-bases-per-file')}
                                name="n"
                            >
                                <InputNumber
                                    precision={0}
                                    placeholder="5000000"
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('params.vcall.number-of-threads')}
                                name="t"
                            >
                                <InputNumber
                                    precision={0}
                                    placeholder="16"
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('params.vcall.verbose')}
                                name="v"
                            >
                                <InputNumber
                                    precision={0}
                                    placeholder="0"
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('params.vcall.work-directory-name-for-vcall')}
                                name="w"
                            >
                                <Input
                                    type="text"
                                    placeholder="tmp1"
                                    onChange={(e) => {
                                        handleRemoveSpaceInput(e, 'w', form);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('params.vcall.work-file-base-name-for-vcall')}
                                name="W"
                            >
                                <Input
                                    type="text"
                                    placeholder="test"
                                    onChange={(e) => {
                                        handleRemoveSpaceInput(e, 'W', form);
                                    }}
                                />
                            </Form.Item>


                            <Form.Item
                                name='extraParameter'
                                label={t('params.prepare.extra-parameter')}
                            >
                                <Input
                                    type="text"
                                    placeholder=''
                                />
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>
        </ModalContainer>

    );
});

