import { Terminal, UserDashboard } from "components/pages/dashboard";
import { routerType } from "types";

 const UserRoute: routerType[] = [
    {
        path: 'user',
        element: <UserDashboard/>,
    },
    {
        path: 'user/terminal',
        element: <Terminal/>,
    },
   
    
];
export default  UserRoute
