interface themeType {
  backgroundAuth: string,
  backgroundDashboard: string,
  bgDashboardHeader: string,
  bgButtonAuth: string
}

export const theme: themeType = {
  backgroundAuth: '#F7FAFC',
  backgroundDashboard: '#EDF2F7',
  bgDashboardHeader: '#2D3748',
  bgButtonAuth: '#2A4365'
};
