import { RegexConstants } from "constant";

export const validator = {
    validateEmail: (value: string | undefined, callback: (isValid: boolean) => void) => {
        const emailRegex = RegexConstants.IsEmail;
        /* /@gmail\.com$/i */
        if (value && emailRegex.test(value)) {
            callback(true); // valid email
        } else {
            callback(false); // invalid email
        }
    }
};

