import { toJS } from "mobx";
import PQueue from "p-queue";
import React, { useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import iconUpload from "assets/icon/iconUploadFile.png";
import useStores from "stores";
import {
  calculateWrapperHeight,
  formatFileSize,
  truncateFileName,
} from "utils";
import {
  FormLabel,
  ModalContainer,
  showNotification,
  StatusFileUpload,
} from "components/elements";
import { CheckOutlined, CloseOutlined, FileOutlined } from "@ant-design/icons";
import { RegexConstants } from "constant";
import { Progress } from "antd";
type FileStatus = "pending" | "success" | "fail";

interface FileState {
  file: File;
  uploadProgress: number;
  status: FileStatus;
}
interface ModalUploadProps {
  validateExtensionFile: string[];
  type: string;
}
export const ModalUploadFile: React.FC<ModalUploadProps> = ({
  validateExtensionFile,
  type,
}) => {
  const { commonStore, parameterStore, userStore } = useStores();
  const { toggleModalUploadFile, showModalUploadFile } = commonStore;
  const [files, setFiles] = useState<FileState[]>([]);
  const userName = toJS(userStore?.currentUser?.username) ?? "";
  const { t } = useTranslation();
  const uploadQueue = useRef<PQueue | null>(null);
  const [uploadCancelled, setUploadCancelled] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [canUpload, setCanUpload] = useState<boolean>(true);
  const handleUploadFile = async (acceptedFiles: File[]) => {
    const validFileNameRegex = RegexConstants.NoSpecialCharFile;

    const filesWithValidNames = acceptedFiles.filter((file) =>
      validFileNameRegex.test(file.name)
    );
    if (filesWithValidNames.length !== acceptedFiles.length) {
      const uniqueFiles = acceptedFiles.filter((file) =>
        filesWithValidNames.includes(file)
      );
      acceptedFiles = uniqueFiles;
      showNotification(
        "validate.file.file-name-no-special-characters",
        "error"
      );
    }

    if (validateExtensionFile.length > 0) {
      const filteredFiles = acceptedFiles.filter((file) => {
        return validateExtensionFile.some((extension) =>
          new RegExp(`${extension}$`).test(file.name)
        );
      });

      if (filteredFiles.length !== acceptedFiles.length) {
        showNotification("notification.file.invalid-type", "error");
        setCanUpload(false);
        return;
      }
    }

    const existingFileNames = files.map((file) => file.file.name);
    const duplicateFiles = acceptedFiles.filter((file) =>
      existingFileNames.includes(file.name)
    );

    if (duplicateFiles.length > 0) {
      showNotification("notification.file.duplicate-file-name", "error");
      const uniqueFiles = acceptedFiles.filter(
        (file) => !duplicateFiles.includes(file)
      );
      acceptedFiles = uniqueFiles;
    }
    abortControllerRef.current = new AbortController();

    const newFiles: FileState[] = acceptedFiles.map((file) => ({
      file,
      uploadProgress: 0,
      status: "pending",
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    newFiles.sort((a, b) => a.file.size - b.file.size);

    uploadQueue.current = new PQueue({ concurrency: 3 });

    newFiles.forEach((newFile) => {
      if (!uploadCancelled && canUpload) {
        uploadQueue.current?.add(() =>
          uploadFile(newFile, abortControllerRef.current?.signal)
        );
      }
    });

    await uploadQueue.current?.onIdle();
  };

  const uploadFile = (newFile: FileState, signal?: AbortSignal) => {
    const isShared = userStore.currentUser?.is_use_shared_workspace ?? false;
    return parameterStore
      .uploadFile(
        isShared,
        type,
        newFile.file,
        userName,
        (progressEvent) => {
          if (progressEvent.total) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.file === newFile.file ? { ...f, uploadProgress: progress } : f
              )
            );
          } else {
            showNotification("validate.file.size", "warning");
          }
        },
        signal
      )
      .then(() => {
        showNotification(`${t("file-upload-success")}`, "success");
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file === newFile.file
              ? { ...f, uploadProgress: 100, status: "success" }
              : f
          )
        );
      })
      .catch(() => {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file === newFile.file
              ? { ...f, uploadProgress: 0, status: "fail" }
              : f
          )
        );
      });
  };

  const cancelUploads = () => {
    toggleModalUploadFile();
    if (uploadQueue.current) {
      uploadQueue.current.clear();
    }
    setUploadCancelled(true);
    setFiles([]);

    // Abort ongoing uploads
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  return (
    <ModalContainer
      title={t("upload-file")}
      open={showModalUploadFile}
      width={600}
      onCancel={cancelUploads}
      footer={false}
    >
      <div className="px-10 mt-5 space-y-4">
        <Dropzone onDrop={handleUploadFile}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={`hover:opacity-70 flex gap-y-2 flex-col justify-center gap-x-1 items-center rounded-xl w-auto px-2 h-[250px] border-dashed border-4 ${
                isDragActive ? "border-green-500" : "border-blue-500"
              } cursor-pointer`}
            >
              <div className="max-w-[80px] max-h-[80px]">
                <img
                  src={iconUpload}
                  className="w-full h-full"
                  alt="file-upload"
                />
              </div>
              <FormLabel text={t("browse-file-to-upload")} />
              {validateExtensionFile?.length > 0 && (
                <span className="text-darkBlue-200 font-bold text-lg text-center">
                  {t("notification.file.fa-fagz")}
                </span>
              )}

              <input {...getInputProps()} className="hidden" />
            </div>
          )}
        </Dropzone>
        {files && files.length > 0 && (
          <div
            className={`w-[full] flex flex-col gap-y-3 customScroll overflow-y-auto ${
              files.length > 3 && "pr-1"
            }`}
            style={{ height: calculateWrapperHeight(files, 100, 300) }}
          >
            {files.map((f, index) => (
              <div
                key={index}
                id="divFile"
                className={`h-[90px] rounded-lg gap-x-3 ${
                  f.status === "pending" ? "bg-blue-100" : "bg-blue-200"
                } flex px-5 py-5 justify-between`}
              >
                <FileOutlined className="text-darkBlue-200 text-4xl" />
                <div className="relative flex flex-col items-center justify-between flex-grow h-full">
                  <div className="flex justify-between w-full text-darkBlue-200 font-bold">
                    {truncateFileName(f.file.name, 35)}
                  </div>
                  {f.uploadProgress > 0 && f.status === "pending" && (
                    <Progress
                      percent={f.uploadProgress}
                      className="w-full h-[10px]"
                      status="active"
                    />
                  )}
                  {["success", "fail"].includes(f.status) && (
                    <>
                      <div className="flex justify-start h-auto w-full">
                        {formatFileSize(f.file.size)}
                      </div>
                      <StatusFileUpload>
                        {f.status === "success" ? (
                          <CheckOutlined className="text-green-500 text-3xl" />
                        ) : (
                          <CloseOutlined className="text-red-100 text-3xl" />
                        )}
                      </StatusFileUpload>
                    </>
                  )}
                  {f.status === "pending" && f.uploadProgress === 0 && (
                    <StatusFileUpload>
                      <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status"
                      ></div>
                    </StatusFileUpload>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
