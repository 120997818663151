import { makeAutoObservable } from "mobx";
import { ContainerRequest } from "../request";
import { CancelToken } from "axios";
import { ContainerResponse, ContainerType, DataResponse, HttpStatusCode, ProcessType } from "types";

class ContainerStore {
    currentContainer: ContainerType | null = null;
    containers: ProcessType[] = []
    page: number = 1
    limit: number = 10
    total: number = 2
    from: number = 1;
    to: number = 10;
    search: string = ''
    constructor() {
        makeAutoObservable(this);
    }
    reset = () => {
        this.page = 1;
        this.limit = 10;
        this.search = ''
    }
    setlimit = (limit: number) => this.limit = limit
    
    setSearch = (Search: string) => this.search = Search

    setPage = (Page: number) => this.page = Page

    getContainer = (): Promise<ContainerResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ContainerRequest.getContainer();
                if (res.data.statusCode === HttpStatusCode.OK) {
                    this.setContainer(res.data?.container);
                    resolve(res.data);
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            }
        })
    };
    getAllContainer = async (
        page?: number,
        limit?: number,
        search?: string,
        cancelToken?: CancelToken
    ): Promise<DataResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ContainerRequest.getAllContainer(page, limit, search, cancelToken);
                if (res.data.statusCode === HttpStatusCode.OK && res.data.userResult) {
                    const containers = res.data.userResult
                    this.setContainers(containers);
                    this.page = Number(res?.data.page)
                    this.limit = Number(res?.data.limit)
                    this.total = res?.data.total
                    this.from = res.data.from
                    this.to = res.data.to
                    resolve(res.data)
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            } 
        })
    }


    startContainer = async (): Promise<ContainerResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ContainerRequest.startContainer();
                if (res.data.statusCode === HttpStatusCode.OK) {
                    resolve(res.data)
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            }
        })
    };

    stopContainer = (id: string): Promise<ContainerResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await ContainerRequest.stopContainer(id);
                if (res.data.statusCode === HttpStatusCode.OK) {
                    const remainingContainers = this.containers.filter((container) => container.user._id !== id);
                    this.containers = remainingContainers
                    this.to = this.to - 1
                    this.total = this.total - 1
                    resolve(res.data)
                }else{
                    reject(res.data)
                }
            } catch (error) {
                reject(error)
            }
        })
    };

    setContainer = (data: ContainerType) => {
        this.currentContainer = data;
    };
    setContainers = (data: ProcessType[]) => {
        this.containers = data
    };
}

const containerStore = new ContainerStore();
export default containerStore;
