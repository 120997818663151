import styled from "styled-components";
import { Table } from "antd";
import { useFixTableScroll } from "hook";

const TableCustome = styled(Table)`
  .ant-table-container > .ant-table-content > table > .ant-table-tbody {
    max-height: calc(100vh - 22rem) !important;
    overflow: auto !important;

    &::-webkit-scrollbar-thumb:hover {
      background-color: #2a4365;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #c8daec;
    }
  }

  @media (max-width: 650px) {
    .ant-table-container > .ant-table-body {
      max-height: calc(100vh - 25rem) !important;
    }
  }
`;

export const TableWrapper = ({ ...props }) => {
  const { tableRef, scroll } = useFixTableScroll(1300);

  return <TableCustome {...props} scroll={scroll} ref={tableRef as any} />;
};
