import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Popconfirm, Select, Space, TableProps, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { TableWrapper, CustomTitle } from 'components/elements';
import { handleAxiosError, truncateString } from 'utils';
import { ErrorResponse, UserType } from 'types';
import { useTranslation } from 'react-i18next';
import useStores from 'stores';
import { limitOptions } from 'constant';
import { useState } from 'react';
import { useFetchData, useSetPageTitle } from 'hook';
import ModalProcessUser from './ModalProcessUser';
import { AxiosError } from 'axios';

export const UserManagement = observer(() => {
  const { t } = useTranslation();
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);
  const { userStore } = useStores()
  const { from, to, total, limit, page, search, showModalUser, deleteUser, setPage, setLimit, setSearch, users, getAllUsers, setUserAction } = userStore
  useSetPageTitle('user');
  const {loading,} = useFetchData(getAllUsers, page, limit, search);

  const handleShowModalAdd = () => setUserAction('create')

  const handleShowModalUpdate = (record: UserType) => setUserAction('update', record)

  const columns: TableProps<UserType>["columns"] = [
    {
      title: "ID",
      dataIndex: "_id",
      align: "center",
      render: (_id) => (
        <Tooltip title={_id}>
          <span>{truncateString(_id)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("username"),
      dataIndex: "username",
      align: "center",
      render: (username) => (
        <Tooltip title={username}>
          <span>{truncateString(username)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("email"),
      dataIndex: "email",
      align: "center",
      render: (email) => (
        <Tooltip title={email}>
          <span>{truncateString(email)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("role"),
      dataIndex: "role",
      align: "center",
      width: 130,
      render: (role) => (
        <Tooltip title={t(role)}>
          <span>{t(role)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("use-share-workspace"),
      dataIndex: "is_use_shared_workspace",
      align: "center",
      width: 150,
      render: (isShareWorkspace) => (
        <div className="w-full flex justify-center">
          <Tooltip title={t(isShareWorkspace ? "commons.yes" : "commons.no")}>
            {isShareWorkspace ? (
              <CheckOutlined className="text-xl text-green-500" />
            ) : (
              <CloseOutlined className="text-xl text-red-500" />
            )}
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("action"),
      align: "center",
      dataIndex: "_id",
      fixed: "right",
      width: 100,
      render: (_id, record) => (
        <Space key={`delete-${_id}`} size="middle">
          <Tooltip title={t("commons.update")}>
            <EditOutlined
              key={`edit-${_id}`}
              className="cursor-pointer hover:opacity-70 text-darkBlue-200 text-xl"
              onClick={() => handleShowModalUpdate(record)}
            />
          </Tooltip>
          <Popconfirm
            key={`delete-${_id}`}
            title={t("confirm")}
            placement="left"
            description={t("text-warning-delete-user")}
            onConfirm={() => handleDeleteUser(_id)}
            okButtonProps={{
              loading: isDeletingUser,
              type: 'default',
            }}
            cancelText={t("commons.cancel")}
            okText={t("commons.ok")}
            cancelButtonProps={{
              type: 'primary',
              danger: true,
            }}
          >
            <Tooltip title={t("commons.delete")}>
              <DeleteOutlined
                className="cursor-pointer hover:opacity-70 text-red-100 text-xl"
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDeleteUser = async (id: string) => {
    setIsDeletingUser(true);
    await deleteUser(id).catch((error) => {
      handleAxiosError(error as AxiosError<ErrorResponse>)
    }).finally(() => {
      setIsDeletingUser(false);
    })
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = encodeURIComponent(e.target.value);
    setSearch(search)
  };
  const handleLimitChange = (limit: number) => {
    setLimit(limit)
    setPage(1)
  }
  const handlePageChange = (page: number) => setPage(page)


  return (
    <>
      <div className='space-y-3'>
        <div className='space-y-5'>
          <div className='flex justify-end'>
            <Button onClick={() => handleShowModalAdd()}>
              {t('add-new-user')}
            </Button>
          </div>
          <div className='flex sm:flex-row flex-col gap-y-3 justify-between sm:items-center items-start'>
            <CustomTitle text={t('user-management')} />
            <Input
              onChange={handleSearch}
              placeholder={`${t('search')}...`}
              className='rounded-2xl sm:w-72'
              prefix={<SearchOutlined className='px-2 text-gray-500' />}
            />
          </div>
          <div className='flex justify-between items-center'>
            <div>
              {t('showingEntries', {
                from,
                to,
                total
              })}
            </div>
            <div className='flex justify-end gap-x-3 items-center'>
              <Pagination
                current={page}
                pageSize={limit}
                total={total}
                onChange={(page) => handlePageChange(page)}
              />
              <Select
                defaultValue={10}
                onChange={(limit) => handleLimitChange(limit)}
                options={limitOptions}
                className='w-auto'
              />
            </div>
          </div>
        </div>
          <TableWrapper
            bordered
            columns={columns}
            dataSource={users}
            loading={loading}
            pagination={false}
            rowKey={(record: UserType) => record._id}
            locale={{ emptyText: t('no-data') }}
          />
      </div>
      {showModalUser && <ModalProcessUser />}
    </>
  );
})


