import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useSetPageTitle = (title: string) => {
    const { t } = useTranslation()
    useEffect(() => {
        window.document.title = t(title);
    }, [t, title]);
};

