import { Button, Form, Input, Select, Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "routes/web";
import useStores from "stores";
import {
  EnumRoleTypes,
  CommonUserType,
  EnumLanguage,
  ErrorResponse,
} from "types";
import {
  handleAxiosError,
  handleRemoveSpaceInput,
  validateEmailInput,
  validatePasswordInput,
  validateUsernameInput,
} from "utils";
import { showNotification, FormLabel, CustomTitle } from "components/elements";
import { Languages } from "constant";
import { useSetPageTitle, useReTranslation } from "hook";
import { AxiosError } from "axios";
const { Option } = Select;

const Profile = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const { currentUser, currentRole, updateProfile } = userStore;
  const user = currentUser;
  const navigate = useNavigate();
  const [form] = Form.useForm<CommonUserType>();
  const [isSaving, setIsSaving] = useState(false);
  useReTranslation(form);
  useSetPageTitle("profile");

  const onFinish = (values: CommonUserType) => {
    setIsSaving(true);
    const { role, confirmPassword, ...dataToSubmit } = values;
    updateProfile(dataToSubmit)
      .then(() => {
        showNotification(t("update-successfully"), "success");
      })
      .catch((error) => {
        handleAxiosError(error as AxiosError<ErrorResponse>);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    navigate(
      `${
        currentRole === EnumRoleTypes.USER
          ? webRoutes.dashboard.user.base
          : webRoutes.dashboard.admin.base
      }`
    );
  };
  useEffect(() => {
    if (user) {
      const {
        username,
        email,
        role,
        is_use_shared_workspace,
        default_language,
      } = user;
      form.setFieldsValue({
        username,
        email,
        role: t(`${role}`),
        is_use_shared_workspace: is_use_shared_workspace || false,
        default_language: default_language || EnumLanguage.JP,
      });
    }
  }, []);

  return (
    <div className=" gap-y-6 items-center justify-start ">
      <div className="flex justify-center">
        <CustomTitle text={t("profile")} />
      </div>
      <div className="w-full flex flex-col gap-y-7 mt-10">
        <Form
          form={form}
          name="profileForm"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="username"
            label={<FormLabel text={t("username")} />}
            rules={validateUsernameInput()}
          >
            <Input
              type="text"
              onChange={(e) => handleRemoveSpaceInput(e, "username", form)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={<FormLabel text={t("email")} />}
            rules={validateEmailInput()}
          >
            <Input
              type="input"
              onChange={(e) => handleRemoveSpaceInput(e, "email", form)}
            />
          </Form.Item>

          <Form.Item name="role" label={<FormLabel text={t("role")} />}>
            <Input type="text" disabled className="font-bold" />
          </Form.Item>
          {currentRole === EnumRoleTypes.ADMIN && (
            <>
              <Form.Item
                name="password"
                label={<FormLabel text={t("password")} />}
                rules={validatePasswordInput()}
              >
                <Input.Password
                  onChange={(e) => handleRemoveSpaceInput(e, "password", form)}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label={<FormLabel text={t("confirm-password")} />}
                rules={[
                  {
                    validator: async (_, value: string) => {
                      const password = form.getFieldValue("password");
                      if ((!password && !value) || password === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t("validate.passwords-do-not-match")
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  onChange={(e) =>
                    handleRemoveSpaceInput(e, "confirmPassword", form)
                  }
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="default_language"
            label={<FormLabel text={t("default-language")} />}
          >
            <Select>
              {Languages.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.content}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="is_use_shared_workspace"
            valuePropName="checked"
            initialValue={false}
            label={<FormLabel text={t("use-share-workspace")} />}
          >
            <Checkbox>{t("use-share-workspace")}</Checkbox>
          </Form.Item>
          <div className="flex justify-end gap-x-5 mt-5 ">
            <Button
              size="middle"
              type="primary"
              danger
              onClick={handleCancel}
              disabled={isSaving}
              className="min-w-[6rem]"
            >
              {t("commons.cancel")}
            </Button>
            <Button
              size="middle"
              htmlType="submit"
              disabled={isSaving}
              loading={isSaving}
              className="min-w-[6rem]"
            >
              {isSaving ? t("commons.saving") : t("commons.save")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
});

export default Profile;
