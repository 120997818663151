/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Form, Input, Select } from "antd";
import { FormLabel, showNotification } from "components/elements";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStores from "stores";
import { EnumLanguage, ErrorResponse } from "types";
import {
  handleAxiosError,
  handleRemoveSpaceInput,
  validateEmailInput,
  validatePasswordInput,
  validateUsernameInput,
} from "utils";
import { Languages } from "constant";
import { ModalContainer } from "components/elements";
import { AxiosError } from "axios";

const ModalProcessUser = () => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();
  const { createUser, updateUser, toggleModalUser } = userStore;
  const { type, data: userUpdate } = userStore.userAction;
  const isUpdate = type === "update";

  useEffect(() => {
    if (isUpdate) {
      form.setFieldsValue({
        username: userUpdate?.username,
        email: userUpdate?.email,
        role: t(`${userUpdate?.role}`),
        is_use_shared_workspace: userUpdate?.is_use_shared_workspace || false,
        default_language: userUpdate?.default_language || EnumLanguage.JP,
      });
    } else {
      form.setFieldValue("default_language", EnumLanguage.JP);
    }

    return () => {
      form.resetFields();
    };
  }, [type]);
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const { role, confirmPassword, ...dataToSubmit } = values;
        let action;
        setIsSubmitting(true);

        if (isUpdate) {
          action = updateUser(dataToSubmit, userUpdate?._id);
        } else {
          action = createUser(dataToSubmit);
        }
        action
          .then(() => {
            showNotification(
              isUpdate ? "update-successfully" : "create-successfully",
              "success"
            );
            toggleModalUser();
          })
          .catch((error) => {
            handleAxiosError(error as AxiosError<ErrorResponse>);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      })
      .catch(() => {});
  };

  const handleClose = () => toggleModalUser();

  return (
    <ModalContainer
      title={t(isUpdate ? "update-user" : "add-new-user")}
      isSubmit={isSubmitting}
      open={userStore.showModalUser}
      onCancel={handleClose}
      onOk={onSubmit}
      okText={t(isUpdate ? "commons.update" : "commons.create")}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit} className="px-5">
        <Form.Item
          label={<FormLabel text={t("username")} />}
          name="username"
          rules={validateUsernameInput()}
        >
          <Input
            type="text"
            onChange={(e) => handleRemoveSpaceInput(e, "username", form)}
          />
        </Form.Item>
        <Form.Item
          label={<FormLabel text={t("email")} />}
          name="email"
          rules={validateEmailInput()}
        >
          <Input
            type="text"
            onChange={(e) => handleRemoveSpaceInput(e, "email", form)}
          />
        </Form.Item>
        {isUpdate && (
          <Form.Item label={<FormLabel text={t("role")} />} name="role">
            <Input type="text" disabled={isUpdate} />
          </Form.Item>
        )}
        <Form.Item
          label={<FormLabel text={t("password")} />}
          name="password"
          rules={validatePasswordInput(!isUpdate)}
          required={!isUpdate}
        >
          <Input.Password
            autoComplete="new-password"
            onChange={(e) => handleRemoveSpaceInput(e, "password", form)}
          />
        </Form.Item>
        <Form.Item
          label={<FormLabel text={t("confirm-password")} />}
          name="confirmPassword"
          rules={[
            {
              validator: async (_, value: string) => {
                const password = form.getFieldValue("password");
                if ((!password && !value) || password === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("validate.passwords-do-not-match"));
              },
            },
          ]}
        >
          <Input.Password
            onChange={(e) => handleRemoveSpaceInput(e, "confirmPassword", form)}
          />
        </Form.Item>

        <Form.Item
          label={<FormLabel text={t("default-language")} />}
          name="default_language"
        >
          <Select>
            {Languages.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.content}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="is_use_shared_workspace"
          valuePropName="checked"
          label={<FormLabel text={t("use-share-workspace")} />}
        >
          <Checkbox>{t("use-share-workspace")}</Checkbox>
        </Form.Item>
      </Form>
    </ModalContainer>
  );
};

export default ModalProcessUser;
