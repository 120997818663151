export const webRoutes = {
  default: "/",
  auth: {
    base: '/auth',
    login: 'login',
  },
  dashboard: {
    base: '/dashboard',
    admin: {
      base: '/dashboard/admin',
      process: 'admin/process',
      user: 'admin/user'
    },
    user: {
      base: '/dashboard/user',
      terminal: 'terminal'
    },
    common: {
      profile: 'profile',
      license: 'license'
    }
  }
};
