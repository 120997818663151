import { AxiosError } from 'axios';
import { LoadingSpinner } from 'components/elements';
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { webRoutes } from "routes/web";
import useStores from "stores";
import { EnumRoleTypes, ErrorResponse } from 'types';
import { handleAxiosError } from 'utils';
interface ProtectedRouteProps {
  children: ReactNode;
  roles?: string[];
}

const ProtectedRoute = observer(({ children, roles }: ProtectedRouteProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userStore } = useStores();
  const { getCurrentUser, currentUser } = userStore
  const [isLoading, setLoadingProgress] = useState(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);

  const isValidToken =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");

  useEffect(() => {
    const fetchUserData = () => {
      setLoadingProgress(true);
      getCurrentUser()
      .catch((error)=>{handleAxiosError(error as AxiosError<ErrorResponse>)})
      .finally(() => setLoadingProgress(false))

    };
    if (!isValidToken) {
      navigate(`${webRoutes.auth.base}/${webRoutes.auth.login}`);
      return;
    }

    if (!currentUser) {
      fetchUserData();
    } else {
      setUserRole(currentUser.role);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && userRole) {
      if (roles && roles.indexOf(userRole) === -1) {
        if (userRole === EnumRoleTypes.ADMIN && !location.pathname.includes(EnumRoleTypes.ADMIN)) {
          navigate(`${webRoutes.dashboard.admin.base}`);
          return;
        }
        if (userRole === EnumRoleTypes.USER && !location.pathname.includes(EnumRoleTypes.USER)) {
          navigate(`${webRoutes.dashboard.user.base}`);
          return;
        }
        navigate(`${webRoutes.dashboard.base}`);
      }
    }
  }, [userRole, roles]);

  return isLoading ? <LoadingSpinner allPage={true} /> : <>{children}</>;
});

export default ProtectedRoute;
