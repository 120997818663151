import axios, { AxiosRequestConfig, CancelToken, AxiosProgressEvent } from 'axios';

import authStore from './stores/authStore'
import { CommonUserType, FormDataMatch, FormDataPrepare, FormDataVCall } from 'types';


const requests = {
    get: (url: string, header = false, urlstatic = true, cancelToken?: CancelToken) => {
        if (header && authStore.token) {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
                headers: {
                    Authorization: `Bearer ${authStore.token}`,
                },
                cancelToken,
            });
        }
        if (urlstatic) {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
                cancelToken,
            });
        }
        return axios({
            method: 'get',
            url: `${url}`,
            cancelToken,
        });
    },
    post: (url: string, body: any, header = false, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void, signal?: AbortSignal, dowload?: boolean, cancelToken?: CancelToken) => {
        const config: AxiosRequestConfig = {
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
            data: body,
        };

        if (header) {
            config.headers = {
                Authorization: `Bearer ${authStore.token}`,
            };
        }
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }
        if (cancelToken) {
            config.cancelToken = cancelToken;
        }
        if (signal) {
            config.signal = signal;
        }

        return axios(config);
    },
    delete: (url: string, body: any = false) => {
        const config: AxiosRequestConfig = {
            method: 'delete',
            url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
        };

        if (body) {
            config.data = body;
        }
        if (authStore.token) {
            config.headers = {
                Authorization: `Bearer ${authStore.token}`,
            };
        }

        return axios(config);
    },
    put: (url: string, body: any, header = false, usingPut = false) => {
        const method = usingPut ? 'put' : 'post';
        const config: AxiosRequestConfig = {
            method,
            url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
            data: body,
        };

        if (header) {
            config.headers = {
                Authorization: `Bearer ${authStore.token}`,
            };
        }

        return axios(config);
    },
    patch: (url: string, body: any, header = false) => {
        const method = 'patch';
        const config: AxiosRequestConfig = {
            method,
            url: `${process.env.REACT_APP_API_BASE_URL}${url}`,
            data: body,
        };

        if (header) {
            config.headers = {
                Authorization: `Bearer ${authStore.token}`,
            };
        }

        return axios(config);
    },
};

const AuthRequest = {
    login: (username: string, password: string) => {
        return requests.post(`auth/login`, { email: username, password: password, })
    },
    register: (info: any) => requests.post(`/auth/local/register`, info),
    getCurrentUser: () => requests.get(`user/profile`, true),
};

const ParameterRequest = {
    uploadFile: (isShared: boolean, type: string, file: File, userName: string, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void, signal?: AbortSignal) => {
        const formData = new FormData();
        formData.append('path', `user_${isShared ? 'shared' : `${userName}`}/data`);
        formData.append('file', file);
        return requests.post(`directory-system/${type === '' ? 'upload' : `upload-${type}-file`}`, formData, true, onUploadProgress, signal)
    },
    getFolderByName: () => {
        return requests.get(`directory-system/folder-structure?folder=data`, true)
    },
    runCommand: (name: string, data: FormDataPrepare | FormDataMatch | FormDataVCall) => {
        return requests.post(`docker/run-command-${name}`, data, true)
    },
}
const ContainerRequest = {
    getContainer: () => {
        return requests.get(`docker/check-container`, true)
    },
    getAllContainer: (page?: number, limit?: number, search?: string, cancelToken?: CancelToken) => {
        const params: string[] = [];
        search && params.push(`search=${search}`);
        page && params.push(`page=${page}`);
        limit && params.push(`limit=${limit}`);
        const queryString = params.length > 0 ? `?${params.join('&')}` : '';
        const url = `docker/containers${queryString}`;
        return requests.get(url, true, false, cancelToken);
    },

    startContainer: () => {
        return requests.post('docker/start-container', {}, true)
    },
    stopContainer: (id: string) => {
        return requests.post(`docker/stop-container/${id}`, {}, true)
    }
}
const UserRequest = {
    getAllUsers: (page?: number, limit?: number, search?: string, cancelToken?: CancelToken) => {
        const params: string[] = [];
        search && params.push(`search=${search}`);
        page && params.push(`page=${page}`);
        limit && params.push(`limit=${limit}`);
        const queryString = params.length > 0 ? `?${params.join('&')}` : '';
        const url = `user/getAllUsers${queryString}`;
        return requests.get(url, true, false, cancelToken);
    },

    updateUser: (data: CommonUserType, id: string | undefined) => {
        return requests.patch(`user/updateUser/${id}`, data, true)
    },

    deleteUser: (id: string | undefined) => {
        return requests.delete(`user/delete/${id}`, true)
    },

    createUser: (data: CommonUserType) => {
        return requests.post('user', data, true)
    },
    
    checkLicense: (content: string) => {
        return requests.get(`hash/check-license?content=${content}`, true)
    }
}
export {
    requests,
    AuthRequest,
    ParameterRequest,
    ContainerRequest,
    UserRequest,
};
