import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { TableProps } from "antd";
import { Button, Input, Pagination, Popconfirm, Select, Tooltip } from "antd";
import { AxiosError } from "axios";
import {
  TableWrapper,
  CustomTitle,
  showNotification,
} from "components/elements";
import { limitOptions } from "constant";
import { useFetchData, useSetPageTitle } from "hook";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useStores from "stores";
import { ContainersResponse, ErrorResponse } from "types";
import { handleAxiosError, truncateString } from "utils";

export const ProcessManagement = observer(() => {
  const { t } = useTranslation();
  const { containerStore } = useStores();
  const {
    from,
    to,
    total,
    limit,
    page,
    search,
    containers,
    setlimit,
    setPage,
    setSearch,
    getAllContainer,
  } = containerStore;
  const [isStoppingProcess, setIsStoppingProcess] = useState<boolean>(false);
  const { loading } = useFetchData(getAllContainer, page, limit, search);

  useSetPageTitle("process");
  const columns: TableProps<ContainersResponse>["columns"] = [
    {
      title: "ID",
      dataIndex: "_id",
      align: "center",
      render: (record) => (
        <Tooltip title={record}>
          <span>{truncateString(record)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("username"),
      dataIndex: "user",
      align: "center",
      render: (record) => (
        <Tooltip title={record?.username}>
          <span>{truncateString(record?.username)}</span>
        </Tooltip>
      ),
    },
    {
      title: t("email"),
      dataIndex: "user",
      align: "center",
      render: (record) => (
        <Tooltip title={record?.email}>
          <span>{record?.email}</span>
        </Tooltip>
      ),
    },
    {
      title: t("container-running"),
      dataIndex: "containerId",
      align: "center",
      width: 130,
      render: (record) => (
        <div className="w-full flex justify-center">
          <Tooltip title={t(record ? "commons.start" : "commons.stop")}>
            {record ? (
              <CheckOutlined className="text-xl text-green-500" />
            ) : (
              <CloseOutlined className="text-xl text-red-500" />
            )}
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("action"),
      align: "center",
      fixed: "right",
      width: 130,
      render: (record: ContainersResponse) => (
        <Popconfirm
          title={t("confirm")}
          description={t("text-warning-stop-container-user")}
          onConfirm={() => handleStopProcess(record.user?._id || "")}
          placement="left"
          okButtonProps={{
            loading: isStoppingProcess,
            type: "default",
          }}
          cancelText={t("commons.cancel")}
          okText={t("commons.ok")}
          cancelButtonProps={{
            type: "primary",
            danger: true,
          }}
        >
          <div className="flex justify-center">
            <Tooltip title={t("commons.stop")}>
              <Button
                danger
                type="primary"
                size="small"
                className="rounded-2xl"
              >
                {t("commons.stop")}
              </Button>
            </Tooltip>
          </div>
        </Popconfirm>
      ),
    },
  ];

  const handleStopProcess = (id: string) => {
    setIsStoppingProcess(true);
    containerStore
      .stopContainer(id)
      .then(() => {
        showNotification("container-stop-success", "success");
      })
      .catch((error) => {
        handleAxiosError(error as AxiosError<ErrorResponse>);
      })
      .finally(() => {
        setIsStoppingProcess(false);
      });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleLimitChange = (limit: number) => {
    setlimit(limit);
    setPage(1);
  };

  const handlePageChange = (page: number) => setPage(page);

  return (
    <div className="space-y-3">
      <div className="space-y-5 pt-[3.60rem]">
        <div className="flex sm:flex-row flex-col gap-y-3 justify-between sm:items-center items-start">
          <CustomTitle text={t("process-list")} />
          <Input
            onChange={handleSearch}
            placeholder={`${t("search")}...`}
            className="rounded-2xl sm:w-72"
            prefix={<SearchOutlined className="px-2 text-gray-500" />}
          />
        </div>
        <div className="flex justify-between items-center">
          <div>
            {t("showingEntries", {
              from,
              to,
              total,
            })}
          </div>
          <div className="flex justify-end gap-x-3 items-center">
            <Pagination
              current={page}
              pageSize={limit}
              total={total}
              onChange={(page) => handlePageChange(page)}
            />
            <Select
              defaultValue={10}
              onChange={(limit) => handleLimitChange(limit)}
              options={limitOptions}
            />
          </div>
        </div>
      </div>
      <TableWrapper
        bordered
        columns={columns}
        dataSource={containers}
        loading={loading}
        pagination={false}
        rowKey={(record: ContainersResponse) => record._id}
        locale={{ emptyText: t("no-data") }}
      />
    </div>
  );
});
