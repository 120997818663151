
import { BrandLogo } from 'components/elements';
import { useSetPageTitle } from 'hook';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export const AdminDasboard = observer(() => {
  const { t } = useTranslation();
    useSetPageTitle('dashboard');
  return (
    <div className="w-full flex-1 h-full">
      <div className="flex flex-col gap-y-10 justify-center items-center w-full h-full">
        <h1 className='font-bold text-textColor text-3xl'>{t('welcome-to-aasg')}</h1>
        <div className='max-w-[200px] min-w-[300px] max-h-[70px] min-h-[100px]'>
          <BrandLogo />
        </div>
      </div>
    </div>
  );
});
