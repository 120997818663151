import clsx from "clsx";
import { ReactNode } from "react";

export const RightContentWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex-1 h-full py-5 relative lg:px-10 px-5 overflow-y-hidden">
      <div className={`overflow-y-auto overflow-x-clip customScroll`}>
        <div className={clsx("h-[calc(100vh-100px)] px-8")}>{children}</div>
      </div>
    </div>
  );
};
