import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface ModalContainerProps {
  open: boolean;
  title: string;
  onCancel: () => void;
  onOk?: () => void;
  okText?: string;
  cancelText?: string;
  onClose?: () => void;
  children: React.ReactNode;
  isSubmit?: boolean;
  width?: number;
  footer?: boolean;
  hasScrollBody?: boolean;
}
const styleButton = {
  minWidth: 90,
};
export const ModalContainer: React.FC<ModalContainerProps> = ({
  open,
  title,
  onCancel,
  onOk,
  okText = "commons.ok",
  cancelText = "commons.cancel",
  onClose,
  children,
  isSubmit,
  width = 737,
  footer = true,
  hasScrollBody = true,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      width={width}
      centered
      maskClosable={false}
      onCancel={onCancel}
      onClose={onClose}
      onOk={onOk}
      title={t(title)}
      okText={okText}
      cancelText={cancelText}
      footer={
        footer && (
          <div className="pr-5 space-x-5">
            <Button
              danger
              type="primary"
              onClick={onCancel}
              style={{ ...styleButton }}
            >
              {t(cancelText)}
            </Button>
            <Button
              onClick={onOk}
              loading={isSubmit}
              style={{ ...styleButton }}
            >
              {t(okText)}
            </Button>
          </div>
        )
      }
      classNames={{
        body: `${
          hasScrollBody &&
          "!max-h-[calc(100vh-10rem)] !overflow-y-auto customScroll"
        }`,
      }}
    >
      {children}
    </Modal>
  );
};
