import { profileMenuData } from "constant";
import { Dropdown, MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import authStore from "stores/authStore";

const RenderItem = (): MenuProps["items"] => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clsx = 'text-lg cursor-pointer flex items-center gap-x-3 w-full';
  return profileMenuData.map((item) => ({
    key: item.key,
    label: item.path ? (
      <Link
        to={item.path}
        className={`${clsx} ${item.textColor}`}
      >
        {item.icon}
        {t(item.key)}
      </Link>
    ) : (
      <div
        onClick={() => authStore.logout(navigate)}
        className={`${clsx} ${item.textColor}`}
      >
        {item.icon}
        {t(item.key)}
      </div>
    ),
  }));
};

export const DropdownProfile = () => {
  return (
    <Dropdown
      menu={{ items: RenderItem() }}
      trigger={["click"]}
      placement="bottomLeft"
      arrow
    >
      <UserOutlined className="text-white text-4xl cursor-pointer" />
    </Dropdown>
  );
};