
import { Button, Input, Form } from 'antd';
import { BrandLogo, DropdownLanguage } from 'components/elements';
import { useReTranslation, useSetPageTitle } from 'hook';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { webRoutes } from 'routes/web';
import useStores from 'stores';
import { Language, EnumRoleTypes, ErrorResponse } from 'types';
import { handleAxiosError } from 'utils';
import { AxiosError } from 'axios';
interface LoginFormInputs {
    email: string;
    password: string;
}

const Login = () => {
    const { authStore, commonStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    useSetPageTitle('login');
    useReTranslation(form)
    const handleLogin = () => {
        form.validateFields().then((values: LoginFormInputs) => {
            authStore.login(values.email, values.password).then((res) => {
                const { role } = res.user;
                const targetRoute = webRoutes.dashboard[role as EnumRoleTypes]?.base || webRoutes.default;
                navigate(targetRoute);
            }).catch((error) => {
                handleAxiosError(error as AxiosError<ErrorResponse>);
            })
        }).catch(() => { })
    };

    const handleChangeLanguage = async (item: Language) => commonStore.setSelectedLanguage(item);

    return (
        <>
            <div className='w-20 h-8 mb-4'> <BrandLogo /></div>
            <div className="relative w-[512px] max-w-[512px] mx-auto h-auto rounded-xl bg-white px-8 pb-5 pt-10">
                <div className='absolute right-8 top-5 z-50'>
                    <DropdownLanguage handleChangeLanguage={handleChangeLanguage} />
                </div>
                <Form
                    form={form}
                    onFinish={handleLogin}
                    layout='vertical'
                    validateTrigger={['onSubmit']}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: t('validate.this-field-is-required') }]}
                        required
                        label={t('email-or-username')}

                    >
                        <Input
                            type="text"
                            placeholder={`${t('enter-email-or-username')}...`}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('validate.this-field-is-required') }]}
                        required
                        label={t('password')}
                    >
                        <Input.Password
                            type={'password'}
                            placeholder={`${t('enter-password')}...`}
                            autoComplete='new-password'
                        />
                    </Form.Item>
                    <div className="flex items-center justify-center">
                        <Button size='large' htmlType="submit">
                            {t('commons.sign-in')}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default observer(Login);


