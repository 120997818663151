import useStores from 'stores';
import { Trans, useTranslation } from 'react-i18next';
import { ModalContainer } from 'components/elements';

export const ModalLicense = () => {
    const { commonStore } = useStores();
    const {toggleModalLicense, showModalLicense} = commonStore
    const { t } = useTranslation();
    const handleClose = () => toggleModalLicense();
    const hrefMailto = 'mailto:aasguimail@bachasoftware.com?subject=Get%20key%20number&body=Organization%20name: %0A%0ANumber%20of%20keys%20required: %0A%0AName: %0A%0AEmail: %0A%0APhone number: '
    return (
        <ModalContainer
            open={showModalLicense}
            width={770}
            onCancel={handleClose}
            onClose={handleClose}
            footer={false}
            title={t('how-to-get-license')}
        >
            <div className='p-5 text-lg'>
                    <Trans i18nKey="content-guide-get-license" components={{ href: <a className='text-blue-400 hover:opacity-80' href={hrefMailto} /> }}>
                        Each AASG-UI requires a registration key consisting of 12 digits. The registration key is provided by Bac Ha Software Co., Ltd. via email. To obtain a key, send an email to <a>aasguimail@bachasoftware.com</a> and include the following information:
                    </Trans>
                <div className='ps-12'>
                    <ul className='list-disc'>
                        <li>{t('organization-name')}</li>
                        <li>{t('number-key-require')}</li>
                        <li>{t('personal-in-charge')}</li>
                    </ul>
                </div>
            </div >
        </ModalContainer >
    );
};

