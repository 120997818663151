export const LoadingSpinner = ({allPage}: {allPage?: boolean}) => {
    return (
        <div className={`absolute top-0 left-0 right-0 bottom-0  bg-opacity-80 ${allPage ? 'bg-gray-200': 'bg-gray-100'} flex justify-center items-center`}>
            <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                role="status"
            >
            </div>
        </div>
    );
}