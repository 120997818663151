import { Languages } from "constant";
import { Dropdown, MenuProps } from "antd";
import { EnumLanguage, Language } from "types";
import { ReactComponent as JP } from "assets/svgs/jp.svg";
import { ReactComponent as EN } from "assets/svgs/en.svg";
import useStores from "stores";
import { observer } from "mobx-react-lite";

export const DropdownLanguage = observer(({
  handleChangeLanguage,
}: {
  handleChangeLanguage: (item: Language) => void;
}) => {
  const { commonStore } = useStores();

  const DropdownLanguageItem = (): MenuProps["items"] => {
    return Languages.map((item) => ({
      key: item.key,
      label: (
        <div
          className="flex items-center gap-x-5 "
          onClick={() => {
            handleChangeLanguage(item);
          }}
        >
          {item.key === EnumLanguage.JP ? <JP /> : <EN />}
          <span>{item.label}</span>
        </div>
      ),
    }));
  };
  const menuItems = DropdownLanguageItem();
  return (
    <Dropdown
      placement='bottom'
      className="w-auto"
      menu={{ items: menuItems }}
      trigger={["click"]}
    >
      <div className="w-24 h-[38px] rounded-lg border border-1 bg-white flex justify-between items-center cursor-pointer">
        <div className="w-full flex justify-start gap-x-5 px-4 items-center">
          <div className="w-8 h-5">
            {commonStore?.selectedLanguage?.key === EnumLanguage.EN ? (
              <EN />
            ) : (
              <JP />
            )}
          </div>
          <span className="text-darkBlue-200">
            {commonStore?.selectedLanguage?.label}
          </span>
        </div>
      </div>
    </Dropdown>
  );
})
