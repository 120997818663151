import App from "App";
import { LoadingSpinner } from "components/elements";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.Suspense fallback={<LoadingSpinner allPage={true} />}>
    <App></App>
  </React.Suspense>
);

reportWebVitals();
