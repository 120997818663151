import { ConfigProviderProps } from 'antd/es/config-provider';
import enUSIntl from 'antd/locale/en_US';

export const antdConfig: ConfigProviderProps = {
  theme: {
    components: {
      Input: {
        paddingBlockLG: 15,
        paddingBlockSM: 5,
        controlHeight: 38,
        activeBorderColor: '#d9d9d9',
        hoverBorderColor: '#d9d9d9',
        activeShadow: '#d9d9d9',
      },
      Select: {
        controlHeight: 38
      },
      InputNumber: {
        controlWidth: 641,
        controlHeight: 38,
        hoverBorderColor: '#d9d9d9',
        activeBorderColor: '#d9d9d9',
        activeShadow: '#d9d9d9',
      },
      Checkbox: {
        colorPrimaryHover: '#2A4365',
      },
      Switch: {
        colorPrimary: '#2A4365',
        colorPrimaryHover: '#2A4365'
      },
      Button: {
        defaultBg: '#2A4365',
        defaultHoverBg: '#4B6A8E',
        defaultHoverBorderColor: '#FFF',
        defaultHoverColor: '#FFF',
        defaultActiveBg: '#1F3150',
        defaultActiveBorderColor: '#FFF',
        defaultActiveColor: '#FFF',
        defaultColor: '#FFF',
        contentFontSize: 16,
        paddingBlock: 18,
        paddingInline: 18,
        paddingInlineLG: 40,
        paddingInlineSM: 20,
        controlHeight: 38,
        controlHeightLG: 38
      }
    },
  },
  locale: enUSIntl,
};