import { ApiResponse, ErrorResponse } from "types";
import { RegexConstants } from "constant";
import { validator } from "validator";
import i18next from "i18next";
import { FormInstance, Rule, RuleObject } from "antd/es/form";
import { AxiosError } from "axios";
import { showNotification } from "components/elements";
const t = i18next.t
export const formatFileSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size < 1048576) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size < 1073741824) {
    return `${(size / 1048576).toFixed(2)} MB`;
  } else {
    return `${(size / 1073741824).toFixed(2)} GB`;
  }
};

export const calculateWrapperHeight = (arrayElement: any[], singleElementHeight: number, maxHeight: number) => {
  const fileCount = arrayElement.length;
  const calculatedHeight = Math.min(fileCount * singleElementHeight, maxHeight);
  return calculatedHeight;
};

export const truncateFileName = (name: string, maxLength: number) => {
  if (name?.length <= maxLength) return name;
  const extIndex = name?.lastIndexOf('.');
  const ext = extIndex !== -1 ? name?.substring(extIndex) : '';
  const truncatedName = name?.substring(0, maxLength - ext.length - 3) + '... ' + ext;
  return truncatedName;
};

export const truncateString = (str: string, maxLength: number = 40): string => {
  if (str?.length <= maxLength) return str;

  const truncatedStr = str?.substring(0, maxLength - 3) + '...';

  return truncatedStr;
};

export const setPageTitle = (title: string) => {
  window.document.title = t(title);
};

export const isErrorResponse = (response: ApiResponse | ErrorResponse) => {
  return 'errorCode' in response && 'errorMessage' in response;
};

export const handleRemoveSpaceInput = (
  e: React.ChangeEvent<HTMLInputElement>,
  name: string,
  form: FormInstance
) => {
  const value = e.target.value.replace(/\s/g, "");
  form.setFieldsValue({ [name]: value });
};


export const validatePasswordInput = (isRequire: boolean = false): Rule[] => {
  const rules: (Rule | false)[] = [
    isRequire && {
      required: true,
      message: i18next.t('validate.this-field-is-required'),
    },
    {
      min: 8,
      message: i18next.t('validate.password-min-length'),
    },
    {
      validator: (_: RuleObject, value: string) => {
        if (!value) {
          return Promise.resolve();
        }
        if (!RegexConstants.HasSpecialChar.test(value)) {
          return Promise.reject(new Error(i18next.t('validate.password-special-char')));
        }
        if (!RegexConstants.HasUpperCase.test(value)) {
          return Promise.reject(new Error(i18next.t('validate.password-uppercase')));
        }
        return Promise.resolve();
      },
    },
  ];

  return rules.filter((rule): rule is Rule => !!rule);
};

export const validateEmailInput = (): Rule[] => [
  {
    required: true,
    message: i18next.t('validate.this-field-is-required'),
  },
  {
    validator: (_: RuleObject, value: string) => {
      return new Promise<void>((resolve, reject) => {
        validator.validateEmail(value, (isValid) => {
          if (isValid) {
            resolve();
          } else {
            reject(new Error(i18next.t('validate.invalid-email')));
          }
        });
      });
    },
  },
];
export const validateUsernameInput = (): Rule[] => [
  {
    required: true,
    message: i18next.t('validate.this-field-is-required'),
  },
  {
    min: 2,
    message: i18next.t('validate.username-min-length'),
  },
  {
    pattern: RegexConstants.NoSpecialChar,
    message: i18next.t('validate.username-no-special-characters'),
  },
];

export const handleAxiosError = (error: AxiosError<ErrorResponse>) => {
  const errorMessage = error.response ? error.response.data?.errorMessage : 'something-was-wrong';
  showNotification(errorMessage, 'error');
};

export const filterExistingValues = (data: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(data).filter(([value]) => value)
  );
};







