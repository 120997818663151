import { notification, NotificationArgsProps } from "antd";
import clsx from "clsx";
import i18next from "i18next"; 

type Type = "error" | "success" | "warning";
const t = i18next.t
export const showNotification = (
  message: string,
  type: Type,
  placement: NotificationArgsProps['placement'] = "topRight"
) => {
  const options: NotificationArgsProps = {
    message: t(`commons.${type}`), 
    description: t(`${message}`),
    placement: placement, 
    duration: 2, 
    className: clsx('text-start', {
      'bg-red-50': type === 'error',
      'bg-green-50': type === 'success',
      'bg-yellow-50': type === 'warning',
    })
  };

  switch (type) {
    case "error":
      notification.error(options);
      break;
    case "success":
      notification.success(options);
      break;
    case "warning":
      notification.warning(options);
      break;
    default:
      break;
  }
};
