import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { webRoutes } from "routes/web";
import useStores from "stores";
import { dataRegisterLicense, EnumRoleTypes, ErrorResponse } from "types";
import { FormLabel, CustomTitle, showNotification } from "components/elements";
import { useReTranslation, useSetPageTitle } from "hook";
import { ModalLicense } from "./ModalLicense";
import { handleAxiosError } from "utils";
import { AxiosError } from "axios";

export const LicenseManagement = observer(() => {
  const [form] = Form.useForm<dataRegisterLicense>();
  const { commonStore, userStore } = useStores();
  const { t } = useTranslation();
  const { toggleModalLicense, showModalLicense } = commonStore;
  const { checkLiscense, currentRole } = userStore;
  const [isRegister, setIsRegister] = useState<boolean>(false);
  useReTranslation(form);
  useSetPageTitle("license");
  const navigate = useNavigate();

  const onFinish = (values: dataRegisterLicense) => {
    setIsRegister(true);
    checkLiscense(values.licenseKey)
      .then(() => {
        showNotification(t("register-license-success"), "success");
        navigate(
          `${
            currentRole === EnumRoleTypes.USER
              ? webRoutes.dashboard.user.base
              : webRoutes.dashboard.admin.base
          }`
        );
      })
      .catch((error) => {
        handleAxiosError(error as AxiosError<ErrorResponse>);
      })
      .finally(() => {
        setIsRegister(false);
      });
  };
  const handleShowModalLicense = () => toggleModalLicense();
  return (
    <>
      <div className="h-auto flex flex-col gap-y-5 items-center pt-[3.75rem]">
        <div className="w-full flex flex-row gap-3 justify-start items-center">
          <CustomTitle text={t("register-license")} />
          <div
            className="text-blue-600 cursor-pointer"
            onClick={() => handleShowModalLicense()}
          >
            <InfoCircleOutlined />
          </div>
        </div>
        <div className="w-full flex justify-start items-center">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="w-[350px]"
          >
            <Form.Item
              name="organizationName"
              label={<FormLabel text={t("organization-name")} />}
              rules={[
                {
                  required: true,
                  message: t("validate.this-field-is-required"),
                },
              ]}
            >
              <Input placeholder={t("enter-your-organization-name")} />
            </Form.Item>
            <Form.Item
              name="licenseKey"
              label={<FormLabel text={t("license-key")} />}
              rules={[
                {
                  required: true,
                  message: t("validate.this-field-is-required"),
                },
              ]}
            >
              <Input placeholder={t("enter-license-key")} />
            </Form.Item>
            <div className="flex justify-end">
              <Button htmlType="submit" loading={isRegister}>
                {isRegister
                  ? `${t("commons.registering")}...`
                  : t("commons.register")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {showModalLicense && <ModalLicense />}
    </>
  );
});
