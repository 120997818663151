import { UnorderedListOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores';
import { Language, EnumRoleTypes } from 'types';
import { webRoutes } from 'routes/web';
import { DropdownLanguage, DropdownProfile, BrandLogo } from 'components/elements';

export const Header = observer(() => {
    const { commonStore, userStore } = useStores();
    const { currentUser, currentRole, changeLanguage } = userStore;
    const navigate = useNavigate()

    const handleChangeLanguage = async (item: Language) => {
        if (currentUser) {
            changeLanguage(item)
        }
    };

    const backToHome = () => navigate(`${currentRole === EnumRoleTypes.ADMIN ? webRoutes.dashboard.admin.base : webRoutes.dashboard.user.base}`)
    const toggleSidebar = () => commonStore.toggleSidebar();

    return (
        <div className='h-[67px] bg-darkBlue-100'>
            <div className='h-full lg:px-10 px-10 py-2 flex justify-between items-center'>
                <div className='flex items-center justify-start gap-x-5'>
                    <UnorderedListOutlined onClick={toggleSidebar} className="block xl:hidden text-white cursor-pointer rounded-full p-2" />
                    <div onClick={backToHome} className='h-6 w-16 cursor-pointer'>
                        <BrandLogo />
                    </div>
                </div>
                <div className=' h-full flex justify-between items-center gap-x-8 '>
                    <DropdownLanguage handleChangeLanguage={handleChangeLanguage} />
                    <DropdownProfile />
                </div>
            </div>
        </div>
    );
});
