import { useEffect, useCallback, useRef, useState } from 'react';
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import { DataResponse, ErrorResponse } from 'types';
import { debounce } from 'lodash';
import { debounceTime } from 'constant';
import { handleAxiosError } from 'utils';

export const useFetchData = (
    fetchData: (page?: number, limit?: number, search?: string, cancelToken?: CancelToken) => Promise<DataResponse>,
    page: number,
    limit: number,
    search: string,
    handleResponse?: (res: DataResponse) => void
) => {
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const debouncedFetchData = useCallback(
        debounce(() => {
            setLoading(true)
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Operation canceled due to new request.');
            }
            const newCancelToken = axios.CancelToken.source();
            cancelTokenRef.current = newCancelToken;
            fetchData(page, limit, search, newCancelToken.token).then((res) => {
                handleResponse && handleResponse(res)
            }).catch((error) => {handleAxiosError(error as AxiosError<ErrorResponse>)}).finally(()=>{setLoading(false)});
        }, debounceTime),
        [page, limit, search]
    );

    useEffect(() => {
        debouncedFetchData();
    }, [debouncedFetchData]);

    return {
        loading
    };
};
