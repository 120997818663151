import { SearchOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import iconFile from "assets/icon/iconFile.png";
import useStores from "stores";
import { ModalContainer } from "components/elements";
import { calculateWrapperHeight, handleAxiosError } from "utils";
import { AxiosError } from "axios";
import { ErrorResponse } from "types";

interface CommandProps {
  handleSelectFile: (name: string) => void;
}

export const ModalSelectFile: React.FC<CommandProps> = observer(
  ({ handleSelectFile }) => {
    const { t } = useTranslation();
    const { commonStore, parameterStore, userStore } = useStores();
    const { dataSearchFile, searchFileUpload, loadFiles } = parameterStore;
    const { toggleModalSelect, showModalSelectFile } = commonStore;
    const { currentUser } = userStore;
    const [isLoading, setIsLoading] = useState(false);
    const searchRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const fetchData = () => {
        setIsLoading(true);
        loadFiles()
          .catch((error) => {
            handleAxiosError(error as AxiosError<ErrorResponse>);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      currentUser && fetchData();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      searchFileUpload(value);
    };

    return (
      <ModalContainer
        title={t("select-file")}
        width={900}
        onCancel={() => toggleModalSelect()}
        open={showModalSelectFile}
        footer={false}
        hasScrollBody={false}
      >
        <div className=" flex justify-end">
          <div
            ref={searchRef}
            className="relative w-full bg-white border mb-2 p-2 h-auto flex rounded-lg"
          >
            <div className="h-7 min-w-7 flex justify-center items-center">
              <SearchOutlined className="text-lg" />
            </div>
            <input
              type="text"
              className="h-full flex-1 outline-none ps-2"
              onChange={handleSearch}
            />
          </div>
        </div>
        <div
          style={{ height: calculateWrapperHeight(dataSearchFile, 35, 300) }}
          className={`relative z-30 overflow-y-auto customScroll max-h-[calc(100vh-10rem)]`}
        >
          {isLoading ? (
            <div>{t("status.loading")}</div>
          ) : dataSearchFile.length === 0 ? (
            <div className="opacity-50">{t("file-not-found")}</div>
          ) : (
            dataSearchFile.map((item, index) => (
              <div
                onClick={() => handleSelectFile(item.path)}
                className="flex items-center gap-x-1 relative z-40 mb-2 cursor-pointer group hover:bg-blue-50"
                key={index}
              >
                <div>
                  <img src={iconFile} className="w-9" alt="file-image" />
                </div>
                <div className="w-full whitespace-nowrap overflow-hidden text-ellipsis">
                  {item?.name}
                </div>
              </div>
            ))
          )}
        </div>
      </ModalContainer>
    );
  }
);
