import { Tooltip } from 'antd';
import clsx from 'clsx';
import { UserRoles } from 'constant';
import { useBreakpoint, useSideBar, useSidebarVisibility } from 'hook';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { webRoutes } from 'routes/web';
import useStores from 'stores';
import { EnumRoleTypes } from 'types';
import { truncateString } from 'utils';

export const Sidebar: React.FC = observer(() => {
    const { commonStore, userStore, containerStore } = useStores();
    const { t } = useTranslation();
    const location = useLocation();
    const { currentRole } = userStore
    const useLicense = userStore.currentUser?.license;
    const isOpenSidebar = commonStore.hiddenSidebar;
    const isPC = !useBreakpoint(1280);
    const isMobile = useBreakpoint();
    const isTablet = useBreakpoint(1280);
    const menus = useSideBar(currentRole as UserRoles);

    const handleResetOption = () => {
        userStore.reset();
        containerStore.reset();
    };
    useSidebarVisibility(768, 1280);

    return (
        <div className={clsx(
            'transition-all duration-300 ease-in-out block bg-blueGrey-200',
            {
                'w-0': !isOpenSidebar && (isTablet || isMobile),
                'w-[100px]': isOpenSidebar && (isTablet || isMobile),
                'w-[300px]': isPC,
            }
        )}>

            <div className={clsx(`flex flex-col gap-y-5 items-start h-full pt-20 pb-10 px-3`, { '!px-10': isPC })}>
                {
                    isPC && (
                        <div className={clsx('text-blue-300 text-xl block')}>
                            {currentRole === EnumRoleTypes.ADMIN && t('manage')}
                        </div>
                    )
                }
                <div className='w-full h-full mx-auto xl:gap-y-0 gap-y-10 relative'>
                    <div className='w-full flex flex-col items-center'>
                        {menus.map((item, index) => {
                            const pathSegment = item.key === 'dashboard' ? '' : `/${item.key}`;
                            const isActive = location.pathname === `${currentRole === EnumRoleTypes.USER ? `${webRoutes.dashboard.user.base}` : `${webRoutes.dashboard.admin.base}`}${pathSegment}`;
                            const linkTo = `${currentRole === EnumRoleTypes.USER ? webRoutes.dashboard.user.base : webRoutes.dashboard.admin.base}${pathSegment}`;
                            return (

                                <Tooltip overlayClassName={clsx({ 'hidden': isPC })} placement='rightTop' title={t(`${item.key}`)} key={index}>
                                    <Link
                                        to={linkTo}
                                        onClick={handleResetOption}
                                        className={clsx(
                                            'w-full rounded-xl relative z-20 text-xl cursor-pointer mt-1 h-14 flex items-center justify-center gap-x-4 py-2',
                                            'hover:bg-darkBlue-200 hover:text-white',
                                            {
                                                'group': !isPC && isOpenSidebar,
                                                'bg-darkBlue-200 text-white': isActive,
                                                '!justify-start ps-5': isPC,
                                            }
                                        )}
                                    >
                                        { isOpenSidebar && <div>{item.icon}</div> }
                                        { isPC && <div>{truncateString(t(item.key), 16)}</div> }
                                    </Link>
                                </Tooltip>
                            )
                        })}
                    </div>
                    {currentRole === EnumRoleTypes.ADMIN && isOpenSidebar && (
                        <div className={clsx('font-bold text-lg text-center absolute bottom-0 left-0 right-0')}>
                            {t(useLicense ? 'user-using-license' : 'no-using-license')}
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
});