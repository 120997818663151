import { useMemo } from 'react';
import { sidebars, UserRoles } from 'constant';
import useStores from 'stores';
import { EnumSidebarItems } from 'types';

export const useSideBar = (role: UserRoles | undefined) => {
  const { userStore } = useStores();
  const useLicense = userStore.currentUser?.license;

  return useMemo(() => {
    if (!role) return [];
    
    const sidebarItems = sidebars[role] || [];

    if (useLicense) {
      return sidebarItems.filter(item => item.key !== EnumSidebarItems.LICENSE);
    }

    return sidebarItems;
  }, [role, useLicense]);
};
