import { useEffect, useRef, useState } from "react";
import useStores from "../stores";
import { runInAction } from "mobx";

const useWindowSize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

export default useWindowSize


export const useSidebarVisibility = (from: number, to: number) => {
  const { commonStore } = useStores();
  const windowSize = useWindowSize();
  useEffect(() => {
    if (windowSize.width > from) {
      runInAction(() => { commonStore.hiddenSidebar = true; });
    }
    else if (windowSize.width < from) {
      runInAction(() => {
        commonStore.hiddenSidebar = false
      })
    }
    else {
      runInAction(() => { commonStore.hiddenSidebar = commonStore.hiddenSidebar ? true : false; });
    }
  }, [windowSize.width]);
};

export const useBreakpoint = (breakPoint = 768) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return width < breakPoint;
};

export const useFixTableScroll = (x: number) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState<{ x: number; y?: string }>({ x });
  const { userStore } = useStores();

  useEffect(() => {
    const updateScroll = () => {
      if (tableRef.current) {
        const tableHeight = tableRef.current.clientHeight - 77;
        const viewportHeight = window.innerHeight;
        const thresholdHeight = viewportHeight - 22 * 16;
        if (tableHeight >= thresholdHeight) {
          setScroll({ x, y: 'calc(100vh - 22rem)' });
        } else {
          setScroll({ x });
        }
      }
    };

    updateScroll();

    window.addEventListener('resize', updateScroll);
    return () => {
      window.removeEventListener('resize', updateScroll);
    };
  }, [userStore.limit, window.innerHeight, window.innerWidth]); 

  return { tableRef, scroll };
};


