import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from "assets/svgs/docker.svg";
const NotFoundPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center space-y-5 justify-center h-screen text-center bg-blueGrey-100 text-darkBlue-200 px-4">
            <NotFoundIcon width={300} height={300} />
            <h2 className="text-3xl mt-4 font-bold">{t('404-not-found')}</h2>
            <p className="text-lg mt-4">
                {t('the-page-is-not-exist')}
            </p>
            <Button
                size='large'
                onClick={() => navigate(-1)}>
                {t('go-back')}
            </Button>
        </div>
    );
}

export default NotFoundPage;
