import { Pagination } from "./commons";

export interface ApiResponse {
    statusCode: number;
}
export interface LicenseResponse extends ApiResponse{
    license: boolean
};
export interface UserResponse extends ApiResponse{
    user: UserType
}
export interface UsersResponse extends ApiResponse{
    users: UserType[]
}

export interface DataResponse extends Pagination{
    users?: UserType[];
    userResult? : ProcessType[];
}

/* export interface DataResponse<T> extends ApiResponse {
    data: T;
} */
export interface CommandApiResponse extends ApiResponse {
    message: string;
    command: string;
}
export interface ProcessResponse extends ApiResponse {
    userResult: ProcessType[]
}
export interface ContainerResponse extends ApiResponse{
    container: ContainerType | string
}

export type ProcessType = {
    _id: string;
    deleted: boolean;
    containerId: string;
    user: UserType;
    createdAt: string; 
    updatedAt: string; 
    __v: number;
    isRunning: string[];
}

export type CommonUserType = {
    username?: string;
    email?: string;
    role?: string;
    default_language?: string;
    is_use_shared_workspace?: boolean;
    password?: string;
    confirmPassword?: string;
};

export interface UserType extends CommonUserType {
    _id: string;
    deleted: boolean;
    is_Active: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    license: boolean;
    access_token?: string;
    containers?: ContainerType;
}
export type DataUserTypeKeys = keyof CommonUserType;

export type file = {
    type: string,
    name: string,
    path: string
}
export interface ContainerType {
    _id: string;
    deleted: boolean;
    containerId: string;
    /* user: string; */
    createdAt: string;
    updatedAt: string;
    __v: number
}

export interface ContainersResponse {
    _id: string;
    deleted: boolean;
    containerId: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    user? : UserType
    
}

export interface ErrorResponse {
    errorCode: number;
    errorMessage: string;
}

export enum HttpStatusCode {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    INTERNAL_SERVER_ERROR = 500
}

export interface FormDataMatch {
    A?: string;
    a?: boolean;
    b?: string;
    c?: boolean;
    D?: string;
    d?: number;
    i?: string;
    l?: number;
    M?: number;
    n?: number;
    N?: number;
    q?: string;
    Q?: string;
    r?: string;
    R?: string;
    s?: string;
    S?: string;
    T?: number;
    t?: number;
    v?: number;
    w?: string;
    W?: string;
    x?: number;
    X?: number;
    y?: string;
    extraParameter?: string;
}

export interface FormDataPrepare {
    a: string;
    b?: number;
    d?: number;
    D: string;
    f: string;
    l?: number;
    o: string;
    Q: string;
    s: string;
    v?: number;
    x?: number;
    r?: boolean;
    extraParameter: string
}

export interface FormDataVCall {
    a: boolean;
    B: string;
    d: number;
    D: string;
    i: string;
    l: number;
    m: boolean;
    n: number;
    s: string;
    S: string;
    t: number;
    T: string;
    V: string;
    v: number;
    w: string;
    W: string;
    extraParameter: string
}

export interface UserLoginResponse {
    deleted: boolean;
    email: string;
    role: string;
    username: string;
    createdAt: string;
    updatedAt: string;
    access_token: string;
    lang: string;
}

export interface LoginResponse extends ApiResponse{
   user: UserLoginResponse
}

