import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { Header, Sidebar, RightContentWrapper } from "components/elements";

export const DashboardLayout = observer(() => {
  return (
    <div className="h-screen">
      <Header />
      <div className="flex w-full h-[calc(100%-67px)]">
        <Sidebar />
        <RightContentWrapper>
          <Outlet />
        </RightContentWrapper>
      </div>
    </div>
  );
});