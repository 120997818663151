import i18n from "i18n";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes/browserRouter";
import useStores, { rootStore, RootStoreContext } from "stores";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "styles/globalStyle";
import { theme } from "styles/theme";
import { EnumLanguage } from "types";
import "./i18n";
import "./index.css";
import { ConfigProvider } from "antd";
import { antdConfig } from "configs";

function App() {
  const { commonStore } = useStores();
  const language = commonStore.selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language?.key || EnumLanguage.JP);
    }
  }, [language]);

  return (
    <div className="App">
      <RootStoreContext.Provider value={rootStore}>
        <ThemeProvider theme={theme}>
          <ConfigProvider {...antdConfig}>
            <GlobalStyles />
            <RouterProvider router={router} />
          </ConfigProvider>
        </ThemeProvider>
      </RootStoreContext.Provider>
    </div>
  );
}

export default App;
