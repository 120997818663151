import React from 'react';
import { EnumCommands } from 'types';
import { ModalCommandPrepare } from './ModalCommandPrepare';
import { ModalCommandVCall } from './ModalCommandVCall';
import { ModalCommandMatch } from './ModalCommandMatch';

type ModalMainProps = {
    nameCmd: EnumCommands;
};

const ModalRunCommand: React.FC<ModalMainProps> = ({ nameCmd }) => {
    const cmds = {
        [EnumCommands.PREPARE]: <ModalCommandPrepare nameCmd={nameCmd} />,
        [EnumCommands.MATCH]: <ModalCommandMatch nameCmd={nameCmd} />,
        [EnumCommands.VCALL]: <ModalCommandVCall nameCmd={nameCmd} />,
    }

    const renderModal = (nameCmd : EnumCommands) => {
        return cmds[nameCmd]
    };

    return (
        <>
            {renderModal(nameCmd)}
        </>
    );
}

export default ModalRunCommand;