
import commonStore from "./commonStore";
import userStore from "./userStore";
import authStore from "./authStore";
import { createContext, useContext } from 'react';
import parameterStore from "./parameterStore";
import containerStore from "./containerStore";



class RootStore {
    commonStore: typeof commonStore;
    userStore: typeof userStore;
    authStore: typeof authStore;
    parameterStore: typeof parameterStore;
    containerStore: typeof containerStore;
    constructor(){
        this.commonStore = commonStore
        this.userStore = userStore
        this.authStore = authStore
        this.parameterStore = parameterStore
        this.containerStore = containerStore
    }
}

export const rootStore = new RootStore();

const RootStoreContext = createContext(rootStore);
export { RootStoreContext };
const useStores = () => useContext(RootStoreContext);
export default  useStores;