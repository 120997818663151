import { createBrowserRouter, Navigate } from "react-router-dom";
import { webRoutes } from "./web";
import Login from "components/pages/auth/Login";
import ProtectedRoute from "./protectedRoute";
import userRoute from "./userRoute";
import adminRoute from "./adminRoute";
import Profile from "components/pages/dashboard/Profile";
import { routerType } from "types";
import { ErrorPage} from "components/elements";
import { AuthLayout, DashboardLayout } from "components/layout";
import NotFoundPage from "components/elements/Error/NotFoundPage";

const wrapWithProtectedRoute = (route: routerType, roles: string[]) => ({
  ...route,
  element: <ProtectedRoute roles={roles}>{route.element}</ProtectedRoute>,
  errorElement: <ErrorPage />,
});

export const router = createBrowserRouter([
  {
    path: webRoutes.default,
    element: <Navigate to={`${webRoutes.auth.base}/${webRoutes.auth.login}`} replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.auth.base,
    element: <AuthLayout />,
    children: [
      {
        path: webRoutes.auth.login,
        element: <Login />,
        errorElement: <ErrorPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: webRoutes.dashboard.base,
    element: (
      <ProtectedRoute roles={["admin", "user"]}>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      ...userRoute.map((route) => wrapWithProtectedRoute(route, ["user"])),
      ...adminRoute.map((route) => wrapWithProtectedRoute(route, ["admin"])),
      {
        path: webRoutes.dashboard.common.profile,
        element: <Profile />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
    errorElement: <ErrorPage/>,
  },
]);
