import { FormInstance } from 'antd/es/form';
import i18n from 'i18n';
import { useCallback, useEffect} from 'react';
import type { NamePath } from 'antd/es/form/interface';

export const useReTranslation = (
  form: FormInstance,
) => {
  const validateFormFields = useCallback(() => {
    const errorFields = form.getFieldsError()
      .reduce((arr: NamePath[], field) => {
        if (field.errors.length > 0) {
          arr.push(field.name);
        }
        return arr;
      }, []);

    if (errorFields.length > 0) {
      form.validateFields(errorFields as NamePath[]);
    }
  }, [form]);

  useEffect(() => {
    validateFormFields();
  }, [validateFormFields, i18n.language]);

};
