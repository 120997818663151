
import { AdminDasboard, LicenseManagement, ProcessManagement, UserManagement } from "components/pages/dashboard";
import { routerType } from "types";

const AdminRoute: routerType[] = [
    {
        path: 'admin',
        element: <AdminDasboard />,
    },
    {
        path: 'admin/process',
        element: <ProcessManagement />,
    },
    {
        path: 'admin/user',
        element: <UserManagement />,
    },
    {
        path: 'admin/license',
        element: <LicenseManagement />,
    },
];
export default AdminRoute
