import { createGlobalStyle } from 'styled-components';

// Import font Roboto từ Google Fonts

export const GlobalStyles = createGlobalStyle`
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    overflow-y: hidden
  }
  
 
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;
