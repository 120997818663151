import {
  CreditCardOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { webRoutes } from "routes/web";
import {
  EnumLanguage,
  EnumSidebarItems,
  Language,
  limitOptionProps,
  ProfileMenuItem,
} from "types";

export const limitOptions: limitOptionProps[] = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export enum UserRoles {
  ADMIN = "admin",
  USER = "user",
}

export const Languages: Language[] = [
  {
    label: "JP",
    key: EnumLanguage.JP,
    content: "Japan"
  },
  {
    label: "EN",
    key: EnumLanguage.EN,
    content: "English"
  },
];

export const sidebars = {
  [UserRoles.ADMIN]: [
    {
      key: EnumSidebarItems.DASHBOARD,
      icon: <HomeOutlined />,
      text: "Dashboard",
    },
    {
      key: EnumSidebarItems.USER,
      icon: <UsergroupAddOutlined />,
      text: "User",
    },
    {
      key: EnumSidebarItems.PROCESS,
      icon: <SettingOutlined />,
      text: "Process",
    },
    {
      key: EnumSidebarItems.LICENSE,
      icon: <IdcardOutlined />,
      text: "License",
    },
  ],
  [UserRoles.USER]: [
    {
      key: EnumSidebarItems.DASHBOARD,
      icon: <HomeOutlined />,
      text: "Dashboard",
    },
    {
      key: EnumSidebarItems.TERMINAL,
      icon: <CreditCardOutlined />,
      text: "Terminal",
    },
  ],
};

export const profileMenuData: ProfileMenuItem[] = [
  {
    key: "profile",
    label: "Profile",
    icon: <UserOutlined />,
    path: webRoutes.dashboard.common.profile,
    textColor: "text-darkBlue-200",
  },
  {
    key: "logout",
    label: "Logout",
    icon: <LogoutOutlined />,
    path: "",
    textColor: "text-red-600",
  },
];

export const RegexConstants = {
  HasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
  NoSpecialChar:  /^[a-zA-Z0-9_]+$/,
  NoSpecialCharFile: /^[a-zA-Z0-9._-]+$/,
  HasUpperCase: /[A-Z]/,
  IsEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const debounceTime: number = 500
export const defaultSearchPage: number = 1

