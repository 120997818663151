import { makeAutoObservable } from "mobx";
import i18n from "../i18n";
import { EnumLanguage, Language } from "types";
const storedLanguage = localStorage.getItem('language');
const defaultLanguage = { label: 'JP', key: EnumLanguage.JP, content: 'Japan' };
class CommonStore {
    constructor() {
        makeAutoObservable(this)
    }
    hiddenSidebar: boolean = false;
    showModalCommand: boolean = false;
    showModalUploadFile: boolean = false;
    showModalSelectFile: boolean = false;
    showModalDeleteUser: boolean = false;
    showModalStopContainer: boolean = false;
    showModalLicense: boolean = false;
    showModalAddLicense: boolean = false;
    selectedLanguage: Language = storedLanguage ? JSON.parse(storedLanguage) : defaultLanguage;

    setSelectedLanguage = (item: Language) => {
        this.selectedLanguage = item;
        localStorage.setItem('language', JSON.stringify(item));
        i18n.changeLanguage(item.key);
    }

    currentLanguage?: string = localStorage.getItem('language') || localStorage.getItem('i18nextLng') || 'jp';

    toggleModalUploadFile = () => {
        this.showModalUploadFile = !this.showModalUploadFile;
    };

    toggleModalSelect = () => {
        this.showModalSelectFile = !this.showModalSelectFile;
    };

    toggleSidebar = () => {
        this.hiddenSidebar = !this.hiddenSidebar
    }

    toggleModalCommand = () => {
        this.showModalCommand = !this.showModalCommand
    }

    toggleModalDeleteUser = () => {
        this.showModalDeleteUser = !this.showModalDeleteUser;
    };


    toggleModalStopContainer = () => {
        this.showModalStopContainer = !this.showModalStopContainer;
    };

    toggleModalLicense = () => {
        this.showModalLicense = !this.showModalLicense;
    };

    toggleModalAddLicense = () => {
        this.showModalAddLicense = !this.showModalAddLicense;
    };
}

const commonStore = new CommonStore()
export default commonStore